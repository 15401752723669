import React from "react"
import { Link, graphql } from "gatsby"
import { PrevNext, BlogPreview, TagButtonList } from "components/molecules"
import { Layout } from "components/pageLayouts"
import styles from "./styles.module.scss"

const BlogListTemplate = ({ data, pageContext }) => {
    const { allMarkdownRemark, site } = data
    const { currentPage, numPages, tags: contextTag } = pageContext

    const posts = allMarkdownRemark.edges
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages

    const isTagPage = contextTag.length === 1
    const tagURL = isTagPage && contextTag[0].replace(" ", "-")
    const blogURLStart = `/blog/`
    const tagURLStart = `/tag/${tagURL}/`
    const urlStart = isTagPage ? tagURLStart : blogURLStart

    const prevPage =
        currentPage - 1 === 1
            ? `${urlStart}`
            : `${urlStart}${(currentPage - 1).toString()}`
    const nextPage = `${urlStart}${(currentPage + 1).toString()}`

    return (
        <Layout>
            <div className={"row justify-content-center"}>
                <h2 className={`text-center col mb-5`}>
                    {contextTag.length === 1 ? contextTag : `Archive`}
                </h2>
            </div>
            <div className={"row"}>
                <div className={`col-lg-8`}>
                    {posts.map(post => (
                        <BlogPreview
                            post={post}
                            large={false}
                            key={post.node.id}
                        />
                    ))}
                </div>
                <div className={`col-lg-4`}>
                    <TagButtonList />
                </div>
            </div>
            <PrevNext
                previousLink={prevPage}
                previousTitle="Previous Page"
                nextLink={nextPage}
                nextTitle="Next Page"
                isFirst={isFirst}
                isLast={isLast}
            >
                {Array.from({ length: numPages }, (_, i) => (
                    <li key={`pagination-number${i + 1}`}>
                        <Link
                            activeClassName={styles.activeLink}
                            to={`${urlStart}${i === 0 ? "" : i + 1}`}
                        >
                            {i + 1}
                        </Link>
                    </li>
                ))}
            </PrevNext>
        </Layout>
    )
}

export default BlogListTemplate

export const pageQuery = graphql`
    query blogPageQuery($skip: Int!, $limit: Int!, $tags: [String]) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { tags: { in: $tags } } }
            limit: $limit
            skip: $skip
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        author
                        date(formatString: "MMMM DD, YYYY")
                        description
                        tags
                        title
                        img {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
